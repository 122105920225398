<template lang="pug">
.wrapper
  .container.py-4.text-center
    img.mx-auto(src="@/assets/admin/img/crying.png" style="height: 15rem")
    h1.mt-4 Oops!
    h4.mb-4 I can't seem to find the page you're looking for.
    .brand-link(@click="$router.go(-2)") Go back
</template>

<script>
  export default {
    name: 'NotFoundComponent',
  };
</script>

<style lang="sass" scoped>
  h1
    font-weight: 600
</style>
